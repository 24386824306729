import { Brand, BrandListResponse, BrandProduct } from '~/models/Brand'
import { ShopifyProductId } from '~/models/Product'
import { customFetch } from './repository/customFetch'

type BrandProductsResponse = {
  products: BrandProduct[]
  count: number
}

class BrandRepository {
  private customFetch = customFetch

  public async getBrands(
    size: number,
    after?: number
  ): Promise<BrandListResponse> {
    const { data, error } = await this.customFetch<BrandListResponse>(
      `/catalog/brands`,
      {
        params: {
          size,
          afterTenantId: after,
        },
      }
    )

    if (error.value) {
      throw error.value
    }
    if (!data.value) {
      throw new Error('brands not found.')
    }

    return data.value
  }

  public async getBrand(
    BrandId: number,
    language: string | null = null
  ): Promise<{
    brand: Brand
  }> {
    const { data, error } = await this.customFetch<Brand>(
      `/catalog/brands/${BrandId}`,
      {
        params: {
          channel: 'STORE',
          ...(language ? { lang: language } : {}),
        },
      }
    )

    if (error.value) {
      throw error.value
    }
    if (!data.value) {
      throw new Error('brand not found.')
    }

    return {
      brand: data.value,
    }
  }

  public async getBrandWithProducts(
    size: number,
    tenantId: number,
    afterProductId: ShopifyProductId | null = null,
    lang: string | null = null
  ): Promise<{
    products: BrandProduct[]
    count: number
  }> {
    const { data, error } = await this.customFetch<BrandProductsResponse>(
      `catalog/brands/${tenantId}/products`,
      {
        params: {
          size,
          ...(afterProductId ? { afterProductId } : {}),
          ...(lang ? { lang } : {}),
        },
      }
    )

    if (error.value) {
      throw error.value
    }
    if (!data.value) {
      throw new Error('brand with products not found.')
    }

    return {
      products: data.value.products,
      count: data.value.count,
    }
  }
}

export function useBrandRepository() {
  return new BrandRepository()
}
